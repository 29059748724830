import {
  differenceInSeconds,
  formatISO,
  fromUnixTime,
  getUnixTime,
} from "date-fns";
import { isNumber, max } from "lodash";

import { Fn } from "./fn";
import { Maybe } from "./maybe";
import { now as dirtyNow } from "./now";

/** Type alias for epoch timestamps (seconds since Unix epoch) */
type EpochDate = number;

/** Gets the current time as an epoch timestamp (seconds) */
export const now = (): EpochDate => getUnixTime(dirtyNow());

/** Converts a Date object to an epoch timestamp (seconds) */
export const toEpoch = (date: Date): EpochDate => getUnixTime(date);

/** Converts an epoch timestamp (seconds) to a Date object */
export const toJSDate = (epoch: EpochDate): Date => fromUnixTime(epoch);
export const fromEpoch = toJSDate; // Convenience alias

/** Checks if a value is a valid epoch timestamp */
export const isEpoch = (value: unknown): value is EpochDate =>
  isNumber(value) && Number.isInteger(value) && value >= 0;

export const isAfter = (a: EpochDate, b: EpochDate): boolean => a > b;

export const isPast = (epoch: EpochDate): boolean => isAfter(epoch, now());

export function latest(...epochs: Maybe<EpochDate>[]): Maybe<EpochDate> {
  return max(epochs);
}

export const format = (epoch: EpochDate, fn: Fn<Date, string>): string =>
  fn(toJSDate(epoch));
export const formatEpoch = format; // Convenience alias

export const transform = <R>(epoch: EpochDate, fn: Fn<Date, R>): R =>
  fn(toJSDate(epoch));
