import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { map } from "lodash";

import { log } from "@utils/debug";
import { Maybe } from "@utils/maybe";

import { graphql as gql } from "./gen/gql";

let auth: Maybe<string> = undefined;
const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (auth) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${auth}`,
      },
    }));
  }

  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => !!error,
  },
});

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    map(graphQLErrors, log);
  }

  if (networkError) {
    // handle network error
    log(networkError);
  }
});

const httpLink = new HttpLink({ uri: "/api/graphql" });

const client = new ApolloClient({
  link: from([logErrors, retryLink, authLink, httpLink]),
  cache: new InMemoryCache({
    // Required for GQL – Update on new entity
    // TODO: Potentially remove this when we have a better way to handle
    possibleTypes: {
      Fetchable: [
        "Error",
        "Person",
        "Task",
        "Outcome",
        "Page",
        "Campaign",
        "Calendar",
        "Content",
        "Backlog",
        "Roadmap",
        "Sprint",
        "Schedule",
        "Project",
        "Meeting",
        "Agenda",
        "Action",
        "Team",
        "View",
        "Note",
        "Resource",
        "Process",
        "Form",
        "Event",
        "Request",
        "Pipeline",
        "Workflow",
        "WorkflowStep",
        "KnowledgeBase",
        "Workspace",
      ],
    },
    typePolicies: {
      Link: {
        keyFields: ["url"],
      },
    },
  }),
});

export const setTractionToken = (token: string) => {
  auth = token;
};

export const mutate: typeof client.mutate = (opts) =>
  client
    .mutate({
      errorPolicy: "all",
      ...opts,
    })
    .then((res) => {
      if (res.errors) {
        throw res.errors?.length > 1 ? res.errors : res.errors[0];
      }
      return res;
    });

// Expose appolo query func
export const query: typeof client.query = (opts) =>
  client
    .query({
      errorPolicy: "all",
      fetchPolicy: "network-only",
      ...opts,
    })
    .then((res) => {
      if (res.errors) {
        throw res.errors?.length > 1 ? res.errors : res.errors[0];
      }
      return res;
    });

// Expose gql typed builder
export { gql };
