import { ViewGrouping, ViewLayout } from "@api";

import { debug } from "@utils/debug";
import { switchEnum } from "@utils/logic";

import { ImageIcon } from "@ui/icon";

export const ViewIcon = ({ layout }: { layout: ViewLayout | ViewGrouping }) =>
  switchEnum(layout, {
    default: () => <ImageIcon url={"/board-rows-color.png"} />,
    columns: () => <ImageIcon url={"/board-columns-color.png"} />,
    rows: () => <ImageIcon url={"/board-rows-color.png"} />,
    calendar: () => <ImageIcon url={"/board-calendar-color.png"} />,
    quadrants: () => <ImageIcon url={"/board-quadrants-color.png"} />,
    canvas: () => <ImageIcon url={"/board-canvas-color.png"} />,
    table: () => <ImageIcon url={"/board-table-color.png"} />,
    timeline: () => <ImageIcon url={"/board-timeline-color.png"} />,
    card: () => <ImageIcon url={"/board-cards-color.png"} />,
    columns_rows: () => <ImageIcon url={"/board-group-both.png"} />,
    list: () => <ImageIcon url={"/board-rows-color.png"} />,
    board: () => <ImageIcon url={"/board-columns-color.png"} />,
    browser: () => <ImageIcon url={"/board-browser-color.png"} />,
    else: () => {
      debug("Unknown view layout.", { layout });

      return <ImageIcon url={"/board-rows-color.png"} />;
    },
  });
