import { CSSProperties } from "react";

import { Color, isPerson, isTeam, Person, Team, Workspace } from "@api";

import { Maybe } from "@utils/maybe";

import { Icon } from ".";
import { SquareIcon } from "./square";
import { CompanyFilled, EyeSlash } from "./svgs";

type TeamProps = {
  team: Maybe<Team>;
  className?: string;
  color?: Color;
  style?: CSSProperties;
};

export const TeamIcon = ({ team, color, className, style }: TeamProps) => {
  return (
    <SquareIcon
      color={color || team?.color || "gray_5"}
      className={className}
      style={style}
      children={team?.name.substring(0, 1) || <EyeSlash />}
    />
  );
};

export const SpaceIcon = ({
  entity,
}: {
  entity: Maybe<Team | Person | Workspace>;
}) =>
  isTeam(entity) ? (
    <TeamIcon team={entity} />
  ) : isPerson(entity) ? (
    <SquareIcon color="gray_5">
      <Icon icon={EyeSlash} />
    </SquareIcon>
  ) : (
    <SquareIcon color="gray_5">
      <Icon icon={CompanyFilled} />
    </SquareIcon>
  );
