// TODO: Publish as NPM package

// Types
type Maybe<T> = T | undefined;
type ID = string;
type Prefix = string;
type EntityConfig = Prefix | [Prefix, number];

export interface Config {
  generateId?: (length: number) => string;
  defaultLength?: number;
}

export interface Instance<T> {
  HUMAN_ID_REGEX: RegExp;
  HUMAN_ID_REGEX_EXACT: RegExp;
  newId: (type: T) => ID;
  toType: (id: Maybe<ID>) => Maybe<T>;
  toPrefix: (type: T) => Prefix;
  isId: (id: Maybe<string>) => boolean;
  isTypeId: (type: T) => (id: string) => boolean;
  extractIds: (text: string) => ID[];
}

const DEFAULT_CONFIG: Required<Config> = {
  generateId: (length: number) =>
    Math.random()
      .toString(36)
      .substring(2, 2 + length),
  defaultLength: 8,
};

export const configure = <T extends string | symbol>(
  entityConfigs: Record<T, EntityConfig>,
  config: Config = {}
): Instance<T> => {
  const finalConfig = { ...DEFAULT_CONFIG, ...config };

  // Process entity configs into normalized maps
  const typeToPrefix = {} as Record<T, Prefix>;
  const typeToLength = {} as Record<T, number>;
  const prefixToType = {} as Record<Prefix, T>;

  Object.entries(entityConfigs).forEach(([type, config]) => {
    const [prefix, length] = Array.isArray(config)
      ? config
      : [config, finalConfig.defaultLength];

    typeToPrefix[type as T] = prefix;
    typeToLength[type as T] = length;
    prefixToType[prefix] = type as T;
  });

  // Build regex patterns once at configuration time
  const prefixes = Object.keys(prefixToType).join("|");
  const buildRegex = (exact: boolean = false) => {
    const pattern = `${exact ? "^" : "\\b"}(${prefixes})_[a-zA-Z0-9]{4,13}${
      exact ? "$" : "\\b"
    }`;
    return new RegExp(pattern, "g");
  };

  const HUMAN_ID_REGEX = buildRegex(false);
  const HUMAN_ID_REGEX_EXACT = buildRegex(true);

  const newId = (type: T): ID => {
    const prefix = typeToPrefix[type];
    if (!prefix) {
      throw new Error(`Unknown entity type: ${String(type)}`);
    }
    const length = typeToLength[type];
    const id = finalConfig.generateId(length);
    return `${prefix}_${id}`;
  };

  const toType = (id: Maybe<ID>): Maybe<T> => {
    if (!id) return undefined;
    const prefix = id.split("_")[0];
    return prefixToType[prefix] as Maybe<T>;
  };

  const toPrefix = (type: T): Prefix => typeToPrefix[type];

  const isId = (id: Maybe<string>): boolean =>
    !!id && !!id.match(HUMAN_ID_REGEX_EXACT);

  const isTypeId =
    (type: T) =>
    (id: string): boolean =>
      toType(id) === type;

  const extractIds = (text: string): string[] =>
    text.match(HUMAN_ID_REGEX)?.filter(Boolean) ?? [];

  return {
    HUMAN_ID_REGEX,
    HUMAN_ID_REGEX_EXACT,
    newId,
    toPrefix,
    toType,
    isId,
    isTypeId,
    extractIds,
  };
};
