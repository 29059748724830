import {
  addDays,
  differenceInCalendarDays,
  differenceInSeconds,
  endOfDay,
  format as formatDateFn,
  formatDistanceToNow,
  formatISO as _formatISO,
  getQuarter,
  getWeek,
  isValid,
  parse,
  startOfWeek,
  subDays,
} from "date-fns";
import { isNumber, isString, reduce } from "lodash";

import { Period } from "@api";

import { fromPointDate, ISODate, useISODate } from "./date-fp";
import { Fn, use } from "./fn";
import { switchEnum } from "./logic";
import { abs } from "./math";
import { Maybe, when } from "./maybe";
import { now } from "./now";
import { inFuture } from "./time";

const STRIP_TIMEZONE = /(Z)|([+-]\d{2}:\d{2})/g;

export type Timestamp = number;

export const isToday = (d: Date) => daysAgo(d) === 0;

export const formatISO = (d: Date, opts?: { milliseconds: boolean }) =>
  opts?.milliseconds
    ? formatDateFn(d, "yyyy-MM-dd'T'HH:mm:ss.SSSX")
    : _formatISO(d);

export const formatISOMilli = (d: Date) => formatISO(d, { milliseconds: true });

// Assumes a date means the end of the working day
export const toEndOfDay = (d: Date): Date => {
  const _date = new Date(d);
  _date.setHours(17, 0, 0, 0); // 5pm
  return _date;
};

// Assumes a day starts at 9am
export const toStartOfDay = (d: Date): Date => {
  const _date = new Date(d);
  _date.setHours(9, 0, 0, 0); // 9am
  return _date;
};

export function asTimestamp(d: Date | string | number): Timestamp;
export function asTimestamp(d: Maybe<Date | string | number>): Maybe<Timestamp>;
export function asTimestamp(
  d: Maybe<Date | string | number>
): Maybe<Timestamp> {
  return when(d, (d) =>
    (isString(d) ? fromPointDate(d) : isNumber(d) ? new Date(d) : d).getTime()
  );
}

export const fromTimestamp = (d: number): Date => new Date(d);

// Ignores local timezone and returns the current date in UTC
export const asUTC = (d: Date) =>
  new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

export const asString = (d: Date | string): string =>
  isString(d) ? d : formatISO(d);

export const format = (d: Date, form?: string) =>
  form ? formatDateFn(d, form) : formatISO(d);

export const formatRelative = (
  d: Date,
  fallback: Fn<Date, string> = (d) => format(d, "d MMM")
) => {
  const days = daysAgo(d);

  return switchEnum(String(days), {
    "0": "Today",
    "1": "Tomorrow",
    "-1": "Yesterday",
    else: () =>
      use(Math.abs(days), (duration) =>
        duration < 5
          ? days > 0
            ? `in ${duration}d`
            : `${duration}d ago`
          : fallback(d)
      ),
  });
};

export const formatHuman = (
  d: Date,
  includeTime: boolean = true,
  fallback: Fn<Date, string> = (d) => format(d, "d MMM")
) => {
  const withTime = (date: string) =>
    includeTime ? date + " at " + format(d, "h:mma")?.toLowerCase() : date;

  const days = daysAgo(d);

  if (days === 0) {
    return withTime("Today");
  }
  if (days === 1) {
    return withTime("Tomorrow");
  }
  if (days === -1) {
    return withTime("Yesterday");
  }

  if (abs(days) < 5) {
    return withTime(inFuture(d) ? `in ${abs(days)}d` : `${abs(days)}d ago`);
  }

  return withTime(fallback(d));
};

export const formatTime = (d: Date) => format(d, "h:mma");
export const formatShort = (d: Date) => format(d, "dd MMM");
export const formatLong = (d: Date) => format(d, "dd MMM yyyy");
export const formatYear = (d: Date) => format(d, "yyyy");
export const formatMonth = (d: Date) => format(d, "MMMM");
export const formatDay = (d: Date) => format(d, "eee, dd MMM");
export const formatDayShort = (d: Date) => format(d, "dd");

export const withParanthesis = (s: string, append: Maybe<string>) =>
  !!append ? `${s} (${append})` : s;

export const withSpace = (s: string, append: Maybe<string>) =>
  !!append ? `${s} ${append}` : s;

export const timeAgo = (d: Date) => {
  if (inFuture(d)) {
    return "in " + formatDistanceToNow(d);
  }

  const loFormatted = formatDistanceToNow(d);

  if (loFormatted === "less than a minute") {
    return "Just now";
  }

  return loFormatted?.replace("about ", "") + " ago";
};

export const daysAgo = (d: Date) => {
  return differenceInCalendarDays(d, now());
};

export const secondsAgo = (d: Date) => Math.abs(differenceInSeconds(now(), d));

export const tryParse = (input: string, format: string) => {
  const date = parse(input, format, now());
  return isValid(date) ? date : undefined;
};

export const differenceInDays = (d1: Date, d2: Date) =>
  Math.abs(differenceInCalendarDays(d1, d2));

export const durationInDays = (d1: Date, d2: Date) =>
  differenceInDays(d1, d2) + 1;

export const toEndDate = (start: Date, days: number) =>
  endOfDay(addDays(start, Math.max(days - 1, 0)));

export const isBetween = (d: Date, start: Date, end: Date) =>
  d >= start && d <= end;

export const endOfPrevDay = (d: Date) => endOfDay(subDays(d, 1));

export const latest = (...dates: Maybe<ISODate>[]): Maybe<ISODate> =>
  reduce(
    dates,
    (acc, d) => {
      if (!d) return acc;
      if (!acc) return d;

      const date = fromPointDate(d);
      return useISODate(acc, (d) => (date > d ? date : d));
    },
    undefined as Maybe<ISODate>
  );

export const equals = (d1: Maybe<Date>, d2: Maybe<Date>) =>
  !!d1 && !!d2 && d1.getTime() === d2.getTime();

// returns if the date is within the last 10 seconds
export const isNowish = (d: Date) => secondsAgo(d) < 10;

export const formatPeriod = (date: Date, period: Period) => {
  switch (period) {
    case "day":
      return formatDayShort(date);
    case "week":
      return `W${getWeek(date)} (${format(
        startOfWeek(date, { weekStartsOn: 1 }),
        "dd MMM"
      )})`;
    case "quarter":
      return `Q${getQuarter(date)}`;
    case "month":
      return formatMonth(date);
    case "year":
      return formatYear(date);
  }
};
