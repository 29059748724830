import { customAlphabet } from "nanoid";
import { v4 } from "uuid";

import { configure } from "./human-id";
import { Maybe, required, when } from "./maybe";
import { EntityType } from "./shared";

const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz"
);

export const uuid = () => v4();

export const isUUID = (id: string) =>
  (
    id.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    ) || []
  ).length > 0;

export const cid = (length: number) => nanoid(length);

export const shortId = cid;

export const newID = () => Math.random().toString(16).substring(2, 8);

export const asLocal = (id: string) => `[${id}]`;

export const fromLocal = (id: string) => id.replace(/[\[\]]/gi, "");

// Update on new entity

const humanId = configure(
  {
    // 6-character IDs
    team: ["tm", 6],
    calendar: ["ca", 6],
    campaign: ["cm", 6],
    content: ["c", 6],
    person: ["u", 6],
    backlog: ["bl", 6],
    sprint: ["sp", 6],
    workspace: ["w", 6],
    project: ["p", 6],
    roadmap: ["rm", 6],

    // 10-character IDs
    job: ["tj", 10],

    // All others default to 8 characters
    task: "t",
    outcome: "o",
    page: "d",
    knowledgebase: "kb",
    form: "fm",
    process: "ps",
    schema: "sc",
    schedule: "sd",
    person_workspace: "uw",
    resource: "r",
    meeting: "m",
    agenda: "ag",
    action: "ac",
    note: "n",
    view: "v",
    workflow: "wf",
    workflow_step: "ws",
    pipeline: "pl",
    event: "e",
    request: "re",
    company: "co",
    contact: "ct",
    deal: "dl",
  },
  {
    defaultLength: 6, // Most basic entities used 6 chars in original
    generateId: cid,
  }
);

export const {
  HUMAN_ID_REGEX,
  extractIds: extractIDs,
  isId: isHumanId,
  newId: newHumanId,
} = humanId;

export const toHumanPrefix = (type: EntityType) => humanId.toPrefix(type);

export const maybeTypeFromId = <T extends string | EntityType = string>(
  id: Maybe<string>
): Maybe<T> =>
  !id
    ? undefined
    : isLocalID(id)
    ? // Don't know why the cast is needed here since humanId is matchs EntityType
      // And technically string (I thought) but can't return an EntityType when wanting a string
      (humanId.toType(fromLocal(id)) as Maybe<T>)
    : (humanId.toType(id) as Maybe<T>);

export const typeFromId = <T extends string | EntityType = string>(
  id: string
) => required(maybeTypeFromId(id), () => `Invalid traction id (${id}).`) as T;

export const newLocalHumanId = (entity?: EntityType) =>
  asLocal(when(entity, (t) => humanId.newId(t)) || newID());

export const isTemplateId = (id: Maybe<string>) =>
  id?.startsWith("v_tmp") ?? false;

export const isLocalID = (id: Maybe<string>) => id?.startsWith("[") ?? true;

export const isPersistedID = (id: string) => !isLocalID(id);

export const toLocalParts = (id: string): [string, Maybe<string>] => {
  const [unsaved, persisted] = id.replace("[", "").split("]");
  return [unsaved, persisted];
};

export const toReactKey = (id: string) =>
  isLocalID(id) ? toLocalParts(id)[0] : id;

export const asUUID = (id: string) =>
  id.indexOf("-") === -1
    ? id.replace(/^(\w{8})(\w{4})(\w{4})(\w{4})(.*)/gi, "$1-$2-$3-$4-$5")
    : id;

export const newADDDID = (): string => {
  const nums = Math.random().toString().substring(2, 5);
  const seed = Math.random().toString(36).substring(2);
  const char = seed.search(/[a-zA-Z]/gi);
  return seed[char].toUpperCase() + nums;
};

// Uses regex to determine if the passed in ID is one of our human Ids or a local id [v_1234]
export const HUMAN_OR_LOCAL_REGEX =
  /\b[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})\b|\[[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})\]/g;

export const HUMAN_ID_REGEX_EXACT = /^[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})$/g;

// Special checks for higher perf code paths
export const isTeamId = (id: Maybe<string>) => !!id?.startsWith("tm_");
export const isViewId = (id: Maybe<string>) => !!id?.startsWith("v_");
export const isWorkspaceId = (id: Maybe<string>) => !!id?.startsWith("w_");
export const isPersonId = (id: Maybe<string>) => !!id?.startsWith("u_");
export const isNoteId = (id: Maybe<string>) => !!id?.startsWith("n_");
