import { pascalCase } from "change-case";
import { formatISO } from "date-fns";
import { first, map } from "lodash";

import { EntityForType, MapperLookup } from "@api/mappings";

import { isEmpty, omitEmpty } from "@utils/array";
import { ISODate } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { Maybe, maybe, required, when } from "@utils/maybe";

import type {
  DatabaseID,
  Entity,
  EntityType,
  Error,
  FetchOptions,
  FilterOptions,
  FilterQuery,
  ID,
  Job,
  Note,
  Person,
  PropertyDef,
  PropertyRef,
  PropertyType,
  Resource,
  SearchOptions,
  Team,
  View,
  WorkspaceConfig,
} from "../../types";
import * as api from "./graph/api";
import * as Graph from "./graph/types";
import * as mappings from "./mappings";
import { filterMapFetchable, mapFetchables } from "./mappings";

/*
 * Fetching
 */

const getFilter = async <T extends Graph.Fetchable>(
  type: Graph.EntityType,
  scope: Maybe<string>,
  filter?: Graph.FilterInput
): Promise<T[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query Filter($scope: FilterScope!, $filter: FilterInput) {
        filter(scope: $scope, filter: $filter) {
          ids
          items
        }
      }
    `),
    variables: { scope: { scope, entity: type }, filter: filter || {} },
  });

  return (data.filter?.items || []) as T[];
};

const getSearch = async <T extends Graph.Fetchable>(
  type: Graph.EntityType,
  query: string,
  opts: SearchOptions = {}
): Promise<T[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query Search($type: EntityType!, $query: String, $opts: SearchInput) {
        search(type: $type, query: $query, opts: $opts) {
          ids
          items
        }
      }
    `),
    variables: {
      type,
      query: query || "",
      opts: {
        take: opts.limit ?? 100,
        skip: opts.skip,
        templates: opts.templates,
        archived: opts.archived,
        method: opts.method as Maybe<Graph.SearchMode>,
      },
    },
  });

  return (data.search?.items || []) as T[];
};

const getOptimizedFilter = async <T extends Graph.Fetchable>(
  type: Graph.EntityType,
  scope: Maybe<string>,
  filter: Graph.FilterInput,
  since: Maybe<ISODate>
) => {
  const { data } = await api.query({
    fetchPolicy: "no-cache",
    query: api.gql(/* GraphQL */ `
      query OptimizedFilter(
        $scope: FilterScope!
        $filter: FilterInput
        $opts: FetchInput
      ) {
        all: filter(scope: $scope, filter: $filter, opts: { mode: ids }) {
          ids
        }
        changed: filter(scope: $scope, filter: $filter, opts: $opts) {
          items
        }
      }
    `),
    variables: {
      scope: { entity: type, scope },
      filter,
      opts: since
        ? { mode: Graph.FetchMode.Changed, since: since }
        : { mode: Graph.FetchMode.All },
    },
  });

  return {
    all: maybe(data.all?.ids),
    changed: (data.changed?.items || []) as T[],
  };
};

const getFetch = async (ids: ID[]): Promise<Graph.Fetchable[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query Fetch($ids: [ID!]!) {
        fetch(ids: $ids) {
          ids
          items
        }
      }
    `),
    variables: { ids: ids },
  });

  return data.fetch?.items || [];
};

const getOptimizedFetch = async <T extends Graph.Fetchable>(
  ids: ID[],
  since: Maybe<Date>
) => {
  const { data } = await api.query({
    fetchPolicy: "no-cache",
    query: api.gql(/* GraphQL */ `
      query OptimizedFetch($ids: [ID!]!, $opts: FetchInput) {
        changed: fetch(ids: $ids, opts: $opts) {
          items
        }
      }
    `),
    variables: {
      ids: ids,
      opts: since
        ? { mode: Graph.FetchMode.Changed, since: formatISO(since) }
        : { mode: Graph.FetchMode.All },
    },
  });

  return (data.changed?.items || []) as T[];
};

/*
 * Typesafe mapped usage of core getFilter, getFetch, etc.
 */

export const getForFilter = async <
  T extends EntityType,
  E extends EntityForType<T>,
  G extends Extract<MapperLookup, { type: T; to: E }>["from"],
  N extends Extract<MapperLookup, { type: T; to: E; from: G }>["key"]
>(
  source: { type: T; scope?: string },
  filter?: FilterQuery
): Promise<E[]> => {
  const items = await getFilter<G>(
    source.type as Graph.EntityType,
    source.scope,
    when(filter, mappings.toAPIFilter)
  );
  return filterMapFetchable(items, pascalCase(source.type) as N);
};

export const getForSearch = async <
  T extends EntityType,
  E extends Extract<MapperLookup, { type: T }>["to"],
  G extends Extract<MapperLookup, { type: T; to: E }>["from"],
  N extends Extract<MapperLookup, { type: T; to: E; from: G }>["key"]
>(
  type: T,
  query: string,
  opts: FetchOptions
): Promise<E[]> => {
  const items = await getSearch<G>(type as Graph.EntityType, query, opts);
  return filterMapFetchable(items, pascalCase(type) as N);
};

export const getOptimizedForFilter = async <
  T extends EntityType,
  E extends Extract<MapperLookup, { type: T }>["to"],
  G extends Extract<MapperLookup, { type: T }>["from"],
  N extends Extract<MapperLookup, { to: E }>["key"]
>(
  source: { type: T; scope?: string },
  filter: FilterQuery,
  opts?: FetchOptions
): Promise<{ all: ID[]; changed: E[] }> => {
  const { all, changed } = await getOptimizedFilter<G>(
    source.type as Graph.EntityType,
    source.scope,
    mappings.toAPIFilter(filter),
    opts?.since
  );

  return {
    all: all || [],
    changed: filterMapFetchable(changed, pascalCase(source.type) as N),
  };
};

export const getEntityByIds = async <
  T extends EntityType,
  E extends Extract<MapperLookup, { type: T }>["to"],
  N extends Extract<MapperLookup, { to: E }>["key"]
>(
  type: T,
  ids: ID[]
): Promise<E[]> =>
  filterMapFetchable(await getFetch(ids), pascalCase(type) as N);

export const getOptimizedEntityByIds = async <
  T extends EntityType,
  E extends Extract<MapperLookup, { type: T }>["to"],
  N extends Extract<MapperLookup, { to: E }>["key"]
>(
  type: T,
  ids: ID[],
  since: Maybe<Date>
): Promise<E[]> =>
  filterMapFetchable(
    await getOptimizedFetch(ids, since),
    pascalCase(type) as N
  );

export const getByIds = async (ids: ID[]): Promise<(Entity | Error)[]> =>
  mapFetchables(await getFetch(ids));

export const getOptimizedByIds = async (
  ids: ID[],
  since: Maybe<Date>
): Promise<(Entity | Error)[]> =>
  mapFetchables(await getOptimizedFetch(ids, since));

/*
 * Teams
 */

export const getTeam = async (id: string): Promise<Team> => {
  const teams = await getEntityByIds("team", [id]);
  return required(teams?.[0], () => `Team not found (${id}).`);
};

/*
 * Views
 */

const getViews = async (ids: ID[]): Promise<View[]> =>
  getEntityByIds("view", ids);

// TODO: Convert to OptimizedFilter
const getViewsForFilter = async (filter?: FilterQuery) =>
  getForFilter({ type: "view" }, filter);

export const getView = async (viewId: ID): Promise<Maybe<View>> =>
  first(await getViews([viewId]));

// TODO: Convert to OptimizedFilter
export const getViewsForLocation = async (
  parentId: ID,
  callback?: Fn<View[], void>
) => {
  const views = await getViewsForFilter({
    field: "location",
    op: Graph.FilterOperation.EndsWith,
    type: Graph.PropertyType.Text,
    value: { text: parentId },
  });
  callback?.(views);
  return views;
};

export const getPersons = async (ids?: ID[]) =>
  isEmpty(ids) ? [] : getEntityByIds("person", ids);

export const getPerson = async (personId: ID): Promise<Person> =>
  required(
    first(await getPersons([personId])),
    () => `Could not find person for id ${personId}`
  );

export const getNotes = async (ids: ID[]): Promise<Note[]> =>
  getEntityByIds("note", ids);

// TODO: Convert to OptimizedFilter
export const getRecentNotes = async (
  filter: FilterOptions<Note>
): Promise<Note[]> => {
  const query: FilterQuery = {
    and: omitEmpty([
      {
        field: "createdAt",
        type: "date",
        op: "after",
        value: { date: filter.since },
      },
      when(filter.type, (type) => ({
        field: "type",
        type: "text",
        op: "equals",
        value: { text: type },
      })),
      when(filter.team, (ref) => ({
        field: "location",
        type: "text",
        op: "contains",
        value: { text: ref.id },
      })),
      when(filter.person, (ref) => ({
        field: "refs.followers",
        type: "relations",
        op: "contains",
        value: { relations: [ref] },
      })),
    ]),
  };

  return getForFilter({ type: "note" }, query);
};

export const getNote = async (noteId: ID): Promise<Note> =>
  required(
    first(await getNotes([noteId])),
    () => `Could not find note for id ${noteId}`
  );

/*
 * Resources
 */
export const getLinkMeta = async (url: string) => {
  const { data } = await api.query({
    fetchPolicy: "cache-first",
    // @ts-ignore – donno why this one only is complaining...
    query: api.gql(/* GraphQL */ `
      query LinkMeta($url: URL!) {
        linkMeta(url: $url) {
          confidence
          link {
            ...LinkFragment
          }
        }
      }
    `),
    variables: { url: url },
  });

  return {
    link: mappings.toClientLink(data.linkMeta.link),
    confidence: data.linkMeta.confidence,
  };
};

export const getResources = async (ids: ID[]): Promise<Resource[]> =>
  getEntityByIds("resource", ids);

// TODO: Convert to OptimizedFilter
export const getRecentResources = async (since: ISODate) =>
  getForFilter(
    { type: "resource" },
    {
      field: "createdAt",
      type: "date",
      op: "after",
      value: { date: since },
    }
  );

export const getResource = async (resourceId: ID): Promise<Resource> =>
  required(
    first(await getResources([resourceId])),
    () => `Could not find resource for id ${resourceId}`
  );

/*
 * Property Definitions
 */

export const getPropertyDefinition = async <
  E extends Entity,
  P extends PropertyType
>(
  db: DatabaseID,
  prop: PropertyRef<E, P>
): Promise<PropertyDef<E, P>> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query GetPropertyDefinition(
        $scope: PropertyScope!
        $prop: PropertyRefInput!
      ) {
        propertyDef(scope: $scope, prop: $prop) {
          ...PropertyDefFragment
        }
      }
    `),
    variables: {
      scope: { entity: db.type as Graph.EntityType, scope: db.scope },
      prop: {
        field: prop.field as string,
        type: prop.type as Graph.PropertyType,
      },
    },
  });

  return mappings.toClientPropertyDef<E, P>(data.propertyDef);
};

export const getPropertyDefinitions = async <
  E extends Entity,
  P extends PropertyType
>(
  db: DatabaseID,
  since?: ISODate
): Promise<PropertyDef<E, P>[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query GetPropertyDefinitions($scope: PropertyScope!, $opts: FetchInput) {
        propertyDefs(scope: $scope, opts: $opts) {
          ...PropertyDefFragment
        }
      }
    `),
    variables: {
      scope: { entity: db.type as Graph.EntityType, scope: db.scope },
      opts: since ? { mode: Graph.FetchMode.Changed, since: since } : {},
    },
  });

  return map(data.propertyDefs, (d) => mappings.toClientPropertyDef<E, P>(d));
};

export const getDatabasePropertyValues = async <
  E extends Entity,
  T extends PropertyType
>(
  dbId: DatabaseID,
  prop: PropertyRef<E, T>
): Promise<PropertyDef<E, T>["values"]> => {
  const def = await getPropertyDefinition<E, T>(dbId, prop);
  return def.values as PropertyDef<E, T>["values"];
};

export const getSession = async (): Promise<Maybe<WorkspaceConfig>> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query Session {
        session {
          ...SessionFragment
        }
      }
    `),
  });

  return mappings.toClientWorkspaceConfig(data.session);
};

export const getSwitchableSessions = async (): Promise<WorkspaceConfig[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query SwitchableSessions {
        switchableSessions {
          ...SessionFragment
        }
      }
    `),
  });

  return map(data.switchableSessions, (s) =>
    mappings.toClientWorkspaceConfig(s)
  );
};

export const getJobQueue = async (): Promise<Job[]> => {
  const { data } = await api.query({
    query: api.gql(/* GraphQL */ `
      query JobQueue {
        jobQueue {
          ...JobFragment
        }
      }
    `),
  });

  return map(data.jobQueue, (s) => mappings.toClientJob(s));
};
