import {
  find,
  invert as loInvert,
  isArray,
  isObject,
  keys,
  map,
  reduce,
  sortBy,
  split,
  values,
} from "lodash";

import { maybeMap } from "@utils/array";
import { asString } from "@utils/date";
import { now, toPointDate } from "@utils/date-fp";
import { addInfo, debug } from "@utils/debug";
import { isAnd, isOr } from "@utils/filtering";
import { composel, fallback } from "@utils/fn";
import { ifDo, switchEnum } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import {
  parseDate,
  toDirection,
  toNotionUrl,
  toPlainText,
  toRichTextInput,
} from "@utils/notion";
import { asValue } from "@utils/property-refs";

import {
  Database,
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  Integration,
  Link,
  PersonRef,
  Project,
  PropertyDef,
  PropertyMutation,
  PropertyType,
  PropertyValue,
  PropertyValueRef,
  RelationRef,
  SelectOption,
  SortByProp,
  Status,
  Task,
  Team,
  View,
} from "../../types";
import {
  Database as NotionDB,
  DbFilter,
  DbProperty,
  DbSort,
  Page,
  PageProperties,
  PagePropertyInput,
  RealUser,
  TitleProperty,
  User as NotionUser,
} from "./types";
import { parseMarkdownLink, toMarkdownLink } from "./utils";

type EmptyObject = Record<string, never>;

const orEmpty = <T>(t: T | EmptyObject) =>
  isObject(t) && !keys(t).length ? undefined : (t as T);

const getTitleRichText = (page: Page | NotionDB) => {
  switch (page.object) {
    case "page":
      const prop = find(
        values(page.properties),
        (p) => p.type === "title"
      ) as TitleProperty;
      return orEmpty(prop?.title);
    case "database":
      return (page as NotionDB)?.title;
  }
};

const getIcon = (page: Page | NotionDB) => {
  const icon = page.object === "page" ? page.icon : (page as NotionDB).icon;

  switch (icon?.type) {
    case "emoji":
      return icon?.emoji;
    case "external":
      return icon?.external?.url;
    case "file":
      return icon?.file.url;
    default:
      return undefined;
  }
};

const invert = <T extends Entity>(
  keyMap: Record<keyof T, string>
): Record<string, keyof T> => loInvert(keyMap) as Record<string, keyof T>;

const parseJSON = (json: string, field: string) => {
  try {
    return JSON.parse(json);
  } catch (err) {
    debug(`Failed to parse json`, json, field);
    throw err;
  }
};

/*
 * General Mappings
 */

const toStatusGroup = (status: {
  color?: string;
  name: string;
}): Status["group"] => {
  switch (status.color) {
    case "blue":
    case "red":
      return "in-progress";
    case "green":
      return "done";
    case "gray":
      return "planning";
    default:
      return "not-started";
  }
};

export const toUser = (u: RealUser): PersonRef => ({
  id: u.id,
  name: u.name || "",
  email: u.person?.email,
  avatar: u.avatar_url || undefined,
  source: Integration.Notion,
});

export const toPerson = (u: NotionUser): PersonRef => ({
  id: u.id,
  name: u.name || "",
  avatar: u.avatar_url || undefined,
  source: Integration.Notion,
});

export const toRelationRef = (p: Page): RelationRef => ({
  id: p.id,
  name: toPlainText(getTitleRichText(p)),
  entity: undefined,
});

export const toLink = (p: Page): Link => ({
  text: toPlainText(getTitleRichText(p)),
  url: toNotionUrl(p.id),
  icon: getIcon(p),
});

export const toFilter = (
  filter: FilterQuery,
  keyMap: Record<string, Maybe<string>>
): DbFilter => {
  if (isAnd(filter)) {
    // @ts-ignore
    return { and: map(filter.and, (f) => toFilter(f, keyMap)) };
  }
  if (isOr(filter)) {
    // @ts-ignore
    return { or: map(filter, (f) => toFilter(f, keyMap)) };
  }

  // Nested ORs for multi-value things
  if (isArray(filter.value)) {
    const filters = map(
      filter.value,
      composel(
        (v) =>
          switchEnum(filter.type, {
            select: () => (v as SelectOption).name,
            status: () => (v as SelectOption).name,
            multi_select: () => (v as SelectOption).name,
            person: () => (v as PersonRef).id,
            relation: () => (v as RelationRef).id,
            else: () => v,
          }),
        (v) => ({
          property: keyMap?.[filter.field] || filter.field,
          [filter.type
            ?.replace("status", "select")
            .replace("person", "people")
            .replace("text", "rich_text")]: {
            [filter.op]: v,
          },
        })
      )
    );

    if (filters.length) {
      return ["does_not_equal", "does_not_contain"].includes(filter.op)
        ? ({ and: filters } as DbFilter)
        : ({ or: filters } as DbFilter);
    }

    return filters[0] as DbFilter;
  }

  // If not an array value
  const notionVal = fallback(
    (): PropertyValue[PropertyType] | boolean | undefined =>
      switchEnum(filter.op, {
        is_empty: () => true,
        is_not_empty: () => true,
        else: () => undefined,
      }),
    () =>
      ifDo(
        ["select"].includes(filter.type),
        () => filter.value?.["select"]?.name
      ),
    ifDo(
      ["status"].includes(filter.type),
      () => filter.value?.["status"]?.name
    ),
    () =>
      ifDo(
        ["multi_select"].includes(filter.type),
        () => filter.value?.["multi_select"]?.[0]?.name
      ),
    // () =>
    //   ifDo(
    //     ["person"].includes(filter.type),
    //     () => (filter.value?.["person"] as Maybe<RelationRef>)?.id
    //   ),
    ifDo(
      ["relation"].includes(filter.type),
      () => filter.value?.["relation"]?.id
    ),
    () =>
      filter.type !== "person"
        ? (filter.value?.[filter.type] as { id: ID })?.id
        : undefined,
    () => filter.value
  );

  // @ts-ignore
  return {
    property: keyMap?.[filter.field] || filter.field,
    [filter.type
      ?.replace("status", "select")
      .replace("person", "people")
      .replace("text", "rich_text")]: {
      [filter.op]: notionVal,
    },
  };
};

export const toSort = (
  sort: SortByProp[],
  keyMap: Record<string, Maybe<string>>
): DbSort =>
  sort.map((s) => {
    switch (s.field) {
      case "createdAt":
        return {
          timestamp: "created_time",
          direction: toDirection(s.direction),
        };
      case "updatedAt":
        return {
          timestamp: "last_edited_time",
          direction: toDirection(s.direction),
        };
      default:
        return {
          property: keyMap?.[s.field] || s.field,
          direction: toDirection(s.direction),
        };
    }
  });

/*
 * Task Mappings
 */

export const TaskKeyMap = {
  id: "id",
  notionId: "id",
  orders: "Order",
  title: "Name",
  status: "Status",
  type: "",
  blocked: "",
  summary: "Summary",
  body: "",

  assigned: "Assigned to",
  start: "Start date",
  due: "Due date",
  projects: "Projects",
  links: "Links",
  seenBy: "",
  followers: "",

  code: "Code",

  // Page.properties.Parent
  parent: "Parent",
  subtasks: "Subtasks",

  blockedBy: "Blocked by",
  blocks: "Blocks",
  updates: "Updates",
  resources: "",

  // Page.parent
  source: "parent",

  custom: "",

  team: "Team",
  createdAt: "Created At",
  updatedAt: "Updated At",
  fetchedAt: "",
  createdBy: "Created By",
  updatedBy: "Updated By",
};

/*
 * Project Mappings
 */

export const ProjectKeyMap = {
  id: "id",
  name: "Name",
  status: "Status",
  order: "Order",
  thread: "Thread",
  summary: "Summary",
  body: "",
  notionId: "",

  endsAt: "End Date",
  startsAt: "Start Date",
  links: "",
  updates: "",
  resources: "",
  seenBy: "",
  followers: "",

  // Page.parent
  source: "parent",

  icon: "icon",
  cover: "cover",
  type: "",
  tags: "",
  outcomes: "",
  custom: "",
  settings: "",

  pinned: "Pinned",
  team: "Team",
  archivedAt: "Archived",
  createdAt: "Created At",
  updatedAt: "Updated At",
  fetchedAt: "",
  createdBy: "Created By",
  updatedBy: "Updated By",
};

/*
 * Team Mappings
 */

export const TeamKeyMap = {
  id: "id",
  notionId: "id",
  name: "Name",
  people: "People",
  icon: "Icon", // page.icon

  custom: "",
  settings: "",

  source: "parent", // Page.parent

  createdAt: "Created At",
  updatedAt: "Updated At",
  fetchedAt: "",
};

/*
 * View Mappings
 */
export const ViewKeyMap = {
  id: "id",
  name: "Name",
  icon: "",
  filter: "Filter",
  for: "",
  type: "",
  entity: "",
  group: "Group",
  boardBy: "Group",
  sort: "Sort",
  layout: "Layout",

  parent: "Project",

  showProps: "Display properties",
  hideEmptyBoardBys: "Hide empty groups",

  // Page.parent
  source: "parent",

  team: "Team",
  createdAt: "Created At",
  updatedAt: "Updated At",
  fetchedAt: "",
  createdBy: "Created By",
  updatedBy: "Updated By",
};

export const toKeyMap = (type: EntityType) => {
  switch (type) {
    case "task":
      return TaskKeyMap;
    case "view":
      return ViewKeyMap;
    case "team":
      return TeamKeyMap;
    case "project":
      return ProjectKeyMap;
    default:
      throw new Error("Unsupported keymap.");
  }
};

/*
 * Database Mappings
 */

const matchOrder = <T extends PropertyDef<Entity>>(
  defs: T[],
  original: string[]
): T[] =>
  sortBy(defs, (def, index) => {
    const order = original.indexOf(def.field as string);
    return order >= 0 ? order : original.length + index;
  });

export const toDatabase = <T extends Entity>(db: NotionDB): Database<T> => ({
  id: db.id,
  source: Integration.Notion,
  type: "task",
  props: [],
  //   props: matchOrder(
  //     maybeMap(values(db.properties), (p) =>
  //       toPropertyDefinition(p, invert(TaskKeyMap))
  //     ),
  //     keys(TaskKeyMap)
  //   ),
  updatedAt: toPointDate(parseDate(db.last_edited_time)),
  createdAt: toPointDate(parseDate(db.created_time)),

  // TODO: Remove from things and add to local store
  fetchedAt: now(),
});

export const toPropertyValues = <T extends Entity, R extends PropertyType>(
  property: DbProperty
): PropertyValueRef<T, R>[] => {
  if (property.name === TaskKeyMap["status"] && property.type === "select") {
    return map(property.select?.options || [], (o) => ({
      type: "status" as R,
      field: property.name as keyof T,
      value: {
        status: {
          id: o.id,
          name: o.name,
          color: o.color as Status["color"],
          group: toStatusGroup(o),
        },
      },
    }));
  }

  switch (property.type) {
    case "select":
      return map(property.select?.options || [], (o) => ({
        type: "select" as R,
        field: property.name as keyof T,
        value: asValue("select", o),
      }));

    default:
      return [];
  }
};

/*
 * Property Mappings
 */

const toPropertyValue = <T extends Entity>(
  prop: PageProperties[string],
  key: keyof T
): Maybe<PropertyValue[PropertyType]> => {
  // Treat as select
  if (key === "status" && prop.type === "select" && prop.select) {
    return {
      ...prop.select,
      group: toStatusGroup(prop.select),
    };
  }

  // Support old simple order or new scoped order
  if (key === "orders" && prop.type === "rich_text") {
    const val = when(orEmpty(prop.rich_text), toPlainText);
    if (val?.startsWith("{")) {
      return parseJSON(val, key);
    }
    return { default: val } as Maybe<Record<string, string>>;
  }

  // Parse as markdown links list
  if (prop.type === "rich_text" && ["links"].includes(key as string)) {
    const val = when(orEmpty(prop.rich_text), toPlainText);
    // Ignore empty strings
    return when(val?.trim() || undefined, (val) =>
      map(split(val, ","), (l) => parseMarkdownLink(l.trim()))
    );
  }

  // Parse as JSON objects
  if (
    prop.type === "rich_text" &&
    ["showProps", "filter", "group", "sort"].includes(key as string)
  ) {
    const val = when(orEmpty(prop.rich_text), toPlainText);
    return val ? parseJSON(val, key as string) : undefined;
  }

  switch (prop.type) {
    case "title":
      return when(orEmpty(prop.title), toPlainText);

    case "rich_text":
      return when(orEmpty(prop.rich_text), toPlainText);

    case "email":
      return when(orEmpty(prop.email), toPlainText);

    case "phone_number":
      return when(orEmpty(prop.phone_number), toPlainText);

    case "checkbox":
      return prop.checkbox;

    case "url":
      return when(orEmpty(prop.url), toPlainText);

    case "date":
      return prop.date?.start;

    case "number":
      return prop.number || undefined;

    case "people":
      return map(prop.people, toPerson);

    case "relation":
      return map(prop.relation, (r) => ({
        id: r.id,
        type: (key as Maybe<string>)?.toLowerCase().includes("project")
          ? "project"
          : "object",
      }));

    case "select":
      return prop.select as SelectOption;

    case "multi_select":
      return prop.multi_select as SelectOption[];

    case "created_by":
      return prop.created_by.id;

    case "created_time":
      return toPointDate(new Date(prop.created_time));

    case "last_edited_time":
      return when(prop.last_edited_time, (d) => toPointDate(new Date(d)));

    case "last_edited_by":
      return when(prop.last_edited_by, (b) => b.id);

    default:
      return;
    // throw new Error(`Unsupported update type ${prop.type}.`);
  }
};

const toPropertyInput = <T extends Entity>(
  change: PropertyMutation<T>
): PagePropertyInput[string] | null => {
  const { type, value } = change;
  switch (type) {
    case "title":
      return { title: toRichTextInput(value[type] || "") };

    case "text":
    case "rich_text":
      return { rich_text: toRichTextInput(value["rich_text"]?.markdown || "") };

    case "links":
      return {
        rich_text: toRichTextInput(
          map(value[type], toMarkdownLink)?.join(",") || ""
        ),
      };

    case "email":
      return { email: value[type] || null };

    case "phone":
      return { phone_number: value[type] || null };

    case "url":
      return { url: value[type] || null };

    case "date":
      return {
        date: when(value[type], (v) => ({ start: asString(v) })) ?? null,
      };

    case "boolean":
      return { checkbox: value[type] ?? false };

    case "number":
      return { number: value[type] ?? null };

    case "person":
      return {
        people: maybeMap(value.person || [], (v) =>
          v ? { id: v.id } : undefined
        ),
      };

    // case "multi_select":
    // return { multi_select: map(value[type], (v) => ({ name: v.name })) };

    case "select":
      return { select: { name: value[type]?.name || "" } };

    case "status":
      return { select: { name: value[type]?.name || "" } };

    case "json":
      return {
        rich_text:
          when(value[type], (v) => toRichTextInput(JSON.stringify(v))) ?? [],
      };

    case "relations":
      return { relation: map(value[type], (v) => ({ id: v.id })) };

    case "relation":
      return { relation: [{ id: value[type]?.id || "" }] };

    default:
      addInfo(change);
      throw new Error("Unsupported update type.");
  }
};

// const toPropertyDefinition = <T extends Entity>(
//   property: DbProperty,
//   propMap: Record<string, string> = {}
// ): Maybe<PropertyDef<T>> => {
//   if (property.type === "select" && "status" === propMap[property.name]) {
//     return {
//       field: propMap[property.name] || property.name,
//       type: "status",
//       values: map(property.select.options, (o) => ({
//         id: o.id,
//         name: o.name,
//         color: o.color,
//         group: toStatusGroup(o),
//       })),
//     } as PropertyDef<T>;
//   }

//   switch (property.type) {
//     case "title":
//     case "rich_text":
//     case "email":
//     case "checkbox":
//     case "url":
//     case "date":
//     case "number":

//     case "relation":
//       return {
//         field: propMap[property.name] || property.name,
//         type: property.type,
//         values: [],
//       };

//     case "relation":
//       return {
//         field: propMap[property.name] || property.name,
//         type: property.type,
//         values: [],
//       };

//     case "people":
//       return {
//         field: propMap[property.name] || property.name,
//         type: "person",
//         values: [],
//       };

//     case "multi_select":
//       return {
//         field: propMap[property.name] || property.name,
//         type: "multi_select",
//         values: property.multi_select.options as SelectOption[],
//       };
//     case "select":
//       return {
//         field: propMap[property.name] || property.name,
//         type: property.type,
//         values: property.select.options as SelectOption[],
//       };

//     default:
//       return undefined;
//   }
// };

export const fromPage = <T extends Entity>(
  page: Page,
  keyMap: Record<string, keyof T>
): T => {
  const base = {
    id: page.id,
    // icon: (page.icon as EmojiIcon)?.emoji as Maybe<string>,
    fetchedAt: now(),
  } as Partial<T>;

  if (!!keyMap.cover) {
    (base as Partial<Project>).cover =
      (page.cover?.type === "external"
        ? page.cover?.external?.url
        : page.cover?.file?.url) || "/IMG_cover-default.jpg";
  }

  return reduce(
    keys(page.properties),
    (t, key) => {
      const ourKey = keyMap[key] as keyof T;
      t[ourKey] = toPropertyValue(page.properties[key], ourKey) as any;
      return t;
    },
    base
  ) as T;
};

export const toPageProperties = <T extends Entity>(
  changes: PropertyMutation<T>[],
  source: DatabaseID
): PagePropertyInput => {
  const keyMap = toKeyMap(source.type) as Record<string, string>;
  return reduce(
    changes,
    (curr, change) => ({
      ...curr,
      [keyMap[change.field as string]]: toPropertyInput(change),
    }),
    {}
  );
};

export const toTask = (p: Page): Task => ({
  ...(fromPage(p, invert(TaskKeyMap)) as Task),

  source: {
    scope: (p.parent as { database_id: string }).database_id,
    source: Integration.Notion,
    type: "task",
  },
});

export const toTeam = (p: Page): Team => {
  const team = fromPage(p, invert(TeamKeyMap)) as Team;
  return {
    ...team,

    source: {
      scope: (p.parent as { database_id: string }).database_id,
      source: Integration.Notion,
      type: "team",
    },
  };
};

export const toView = (p: Page): View => ({
  ...(fromPage(p, invert(ViewKeyMap)) as View),

  source: {
    scope: (p.parent as { database_id: string }).database_id,
    source: Integration.Notion,
    type: "view",
  },
});

export const toProject = (p: Page): Project => ({
  ...(fromPage(p, invert(ProjectKeyMap)) as Project),

  source: {
    scope: (p.parent as { database_id: string }).database_id,
    source: Integration.Notion,
    type: "project",
  },
});
