import { isArray, isString, map, mapValues } from "lodash";

import { MapperLookup, Mappers } from "@api/mappings";

import { now } from "@utils/date-fp";
import { debug } from "@utils/debug";
import { asEnum, asEnumValue } from "@utils/enum";
import { evalFormulas } from "@utils/filtering";
import { switchEnum } from "@utils/logic";
import {
  defs,
  Maybe,
  maybe,
  maybeMap,
  required,
  safeAs,
  SafeRecord,
  when,
} from "@utils/maybe";
import { omitEmpty } from "@utils/object";
import { toFieldName, toRef } from "@utils/property-refs";
import { toBaseScope, toScope } from "@utils/scope";

import type {
  Action,
  Agenda,
  Backlog,
  Calendar,
  Campaign,
  Color,
  Company,
  Contact,
  Content,
  Deal,
  Entity,
  EntityType,
  Error,
  Event,
  FilterQuery,
  Form,
  Job,
  KnowledgeBase,
  Link,
  Meeting,
  Note,
  Outcome,
  Page,
  Person,
  PersonRef,
  Pipeline,
  Process,
  Project,
  PropertyDef,
  PropertyFormat,
  PropertyType,
  RefsData,
  RelationRef,
  Request,
  Resource,
  RichText,
  Roadmap,
  Schedule,
  SelectOption,
  Sprint,
  Status,
  Task,
  Team,
  Update,
  View,
  ViewLayout,
  ViewOptions,
  Workflow,
  WorkflowStep,
  Workspace,
  WorkspaceConfig,
} from "../../types";
import {
  AiAssist,
  DisplayAs,
  ErrorCode,
  ErrorHandle,
  Integration,
  JobStatus,
  NoteType,
  Period,
  PersonRole,
  PropertyVisibility,
  ResourceType,
  TeamVisibility,
  WorkflowAction,
} from "../../types";
import * as Graph from "./graph/types";

const toClientRef = ({ id }: Graph.Ref): RelationRef => ({
  id,
});
const formatRefs = (refs: SafeRecord<string, string | string[]>): RefsData =>
  mapValues(refs, (ids) =>
    isArray(ids) ? maybeMap(ids, toRef) : isString(ids) ? [{ id: ids }] : []
  );

const toNestedFields = <
  T extends Pick<
    Graph.Workflow,
    "refs" | "custom" | "stamps" | "settings" | "template"
  >
>(
  p: T
) => ({
  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  template: maybe(p.template),
});

const toLocationFields = <
  T extends Extract<Graph.Fetchable, { location: string }>,
  TT extends EntityType
>(
  p: T,
  type: TT
) => ({
  location: p.location,
  source: {
    source: Integration.Traction,
    type: type,
    scope: toScope(p.location),
  },
});

const toClientDates = (
  item: Exclude<
    Graph.Fetchable,
    Graph.Error | Graph.Person | Graph.Workspace | Graph.Team
  >
) => ({
  fetchedAt: now(),
  updatedAt: item.updatedAt,
  updatedBy: when(item.updatedBy, toClientRef),
  createdAt: item.createdAt,
  createdBy: when(item.createdBy, toClientRef),
  archivedAt: item.archivedAt,
  archivedBy: when(item.archivedBy, toClientRef),
  deletedAt: item.deletedAt,
  deletedBy: when(item.deletedBy, toClientRef),
});

const toClientRichText = ({
  text,
  html,
  markdown,
  state,
}: Graph.RichText): RichText =>
  omitEmpty({
    text: maybe(text),
    html: maybe(html),
    markdown: maybe(markdown),
    state: maybe(state),
  });

export const toAPIFilter = <T extends Entity>(
  filter: Maybe<FilterQuery<T>>
): Graph.FilterInput => evalFormulas(filter) as Graph.FilterInput;

export const toAPIUpdate = <T extends Entity>(
  update: Update<T>
): Graph.UpdateInput => ({
  id: update.id,
  method: update.method,
  type: required(
    asEnumValue(update.source.type, Graph.EntityType),
    () => "Invalid update type for traction API."
  ),
  scope: toBaseScope(update.source.scope),
  changes: maybeMap(
    (update as Extract<Update<T>, { changes: any }>).changes,
    (c) => {
      const apiType = switchEnum(c.type, {
        title: () => "text",
        url: () => "link",
        person: () => "relation",
        else: () => c.type,
      });

      return {
        type: apiType as Graph.PropertyType,
        field: c.field as string,
        op: c.op as Maybe<Graph.PropertyMutationOperation>,
        value: {
          [apiType]: switchEnum(apiType, {
            link: () =>
              when(c.value.link, ({ text, icon, url }) => ({
                text,
                icon,
                url,
              })),

            links: () =>
              map(c.value.links, ({ text, icon, url, pinned }) => ({
                text,
                icon,
                url,
                pinned,
              })),

            relations: () => map(c.value.relations, ({ id }) => ({ id })),

            relation: () =>
              isArray(c.value.relation)
                ? when(c.value.relation[0]?.id, (id) => ({ id }))
                : when(c.value.relation?.id || undefined, (id) => ({ id })),

            status: () =>
              when(c.value.status, ({ id, name, color, group }) =>
                id ? { id } : { name, color, group }
              ),

            select: () =>
              when(c.value.select, ({ id, name }) => ({ id, name })),

            multi_select: () =>
              map(c.value.multi_select, ({ id, name }) => ({ id, name })),

            else: () => c.value[c.type],
          }),
        },
      };
    }
  ),
  transaction: update.transaction,
});

export const toClientTeam = (team: Graph.Team): Team => ({
  ...team,
  id: required(team.id, () => "Team ID is required."),
  notionId: maybe(team.notionId),
  icon: maybe(team.icon),
  visibility:
    asEnum(defs(team.visibility), TeamVisibility) || TeamVisibility.Private,
  color: maybe(team.color) as Maybe<Color>,
  name: team.name || "",
  people: map(team.people || [], toClientRef),
  subTeams: map(team.subTeams || [], toClientRef),
  parent: when(team.parent, toClientRef),
  owner: when(team.owner, toClientRef),
  views: map(team.views || [], toClientRef),

  refs: formatRefs(team.refs),
  custom: team.custom,
  stamps: team.stamps,
  settings: team.settings,

  location: team.location,
  source: {
    source: Integration.Traction,
    type: "team",
    scope: toScope(team.workspaceId),
  },

  updatedAt: team.updatedAt,
  updatedBy: when(team.updatedBy, toRef),
  createdAt: team.createdAt,
  createdBy: when(team.createdBy, toRef),
  deletedAt: team.deletedAt,
  deletedBy: when(team.deletedBy, toRef),
  fetchedAt: now(),
});

export const toClientLink = ({
  text,
  url,
  icon,
  pinned,
}: Graph.Link): Link => ({
  text: maybe(text),
  url,
  icon: maybe(icon),
  pinned: maybe(pinned),
});

export const toClientError = (error: Graph.Error): Error => ({
  code:
    // @ts-ignore code is mapped to reason in fragments.gql but we are using the entity type Graph defs here...
    asEnum(error.code, ErrorCode) || ErrorCode.Unknown,
  message: error.message,
  handle: when(error.handle, (h) => asEnum(h, ErrorHandle)) || ErrorHandle.Fail,
});

export const toClientTask = (task: Graph.Task): Task => ({
  orders: undefined,

  // Override with what's passed in
  ...task,

  // Custom mappings
  id: defs(task.id),
  notionId: maybe(task.notionId),
  code: defs(task.code),
  summary: when(task.summary, toClientRichText),
  title: task.title || "",
  body: when(task.body, toClientRichText),
  checklist: when(task.checklist, toClientRichText),
  start: task.start,
  end: task.end,

  status: when(maybe(task.status) as Maybe<Status>, (s) => ({
    ...s,
    blocked: maybe(task.blocked),
  })),
  blocked: maybe(task.blocked),

  assigned: when(task.assigned, toClientRef),
  links: map(task.links || [], toClientLink),

  refs: formatRefs(task.refs),
  custom: task.custom,
  stamps: task.stamps,

  location: task.location,
  template: maybe(task.template),
  source: {
    source: Integration.Traction,
    type: "task",
    scope: toScope(task.location),
  },

  fetchedAt: now(),
  updatedAt: task.updatedAt,
  updatedBy: when(task.updatedBy, toClientRef),
  createdAt: task.createdAt,
  createdBy: when(task.createdBy, toClientRef),
  deletedAt: task.deletedAt,
  deletedBy: when(task.deletedBy, toClientRef),
});

export const toClientOutcome = (item: Graph.Outcome): Outcome => ({
  orders: undefined,

  // Override with what's passed in
  ...item,

  // Custom mappings
  id: defs(item.id),
  start: item.start,
  end: item.end,
  notionId: maybe(item.notionId),
  code: defs(item.code),
  summary: when(item.summary, toClientRichText),
  title: item.title || "",
  body: when(item.body, toClientRichText),
  assigned: when(item.assigned, toClientRef),
  // TODO: Convert to string and lookup everywhere?
  status: when(maybe(item.status) as Maybe<Status>, (s) => ({
    ...s,
    blocked: maybe(item.blocked),
  })),
  blocked: maybe(item.blocked),
  links: map(item.links || [], toClientLink),

  refs: formatRefs(item.refs),
  custom: item.custom,
  stamps: item.stamps,

  location: item.location,
  template: maybe(item.template),
  source: {
    source: Integration.Traction,
    type: "outcome",
    scope: toScope(item.location),
  },

  ...toClientDates(item),
});

export const toClientProject = (p: Graph.Project): Project => ({
  cover: undefined,
  thread: undefined,
  type: undefined,

  ...p,
  id: defs(p.id),
  color: maybe(p.color) as Maybe<Color>,
  icon: maybe(p.icon),
  status: safeAs<Status>(p.status),
  notionId: maybe(p.notionId),
  orders: maybe(p.orders),
  owner: when(p.owner, toClientRef),
  name: maybe(p.name),
  summary: when(p.summary, toClientRichText),
  body: when(p.body, toClientRichText),
  pinned: maybe(p.pinned),
  start: p.start,
  end: p.end,
  links: map(p.links || [], toClientLink),
  views: map(p.views, toClientRef),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,

  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "project",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientBacklog = (p: Graph.Backlog): Backlog => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  orders: maybe(p.orders),
  icon: maybe(p.icon),
  fields: p.fields,
  inbox: when(p.inbox, toClientRef),
  views: map(p.views, toClientRef),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,

  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "backlog",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientRoadmap = (p: Graph.Roadmap): Roadmap => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  orders: maybe(p.orders),
  icon: maybe(p.icon),
  fields: p.fields,
  body: when(p.body, toClientRichText),
  owner: when(p.owner, toClientRef),
  period: when(p.period, (id) => ({ id })),
  views: map(p.views, toClientRef),

  source: {
    source: Integration.Traction,
    type: "roadmap",
    scope: toScope(p.location),
  },

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  template: maybe(p.template),
  location: p.location,

  ...toClientDates(p),
});

export const toClientCampaign = (p: Graph.Campaign): Campaign => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  color: maybe(p.color) as Maybe<Color>,
  name: maybe(p.name),
  summary: when(p.summary, toClientRichText),
  body: when(p.body, toClientRichText),
  code: maybe(p.code),
  orders: maybe(p.orders),
  start: p.start,
  end: p.end,
  status: safeAs<Status>(p.status),
  owner: when(p.owner, toClientRef),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,

  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "campaign",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientCalendar = (p: Graph.Calendar): Calendar => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  orders: maybe(p.orders),
  owner: when(p.owner, toClientRef),
  views: map(p.views, toClientRef),

  source: {
    source: Integration.Traction,
    type: "calendar",
    scope: toScope(p.location),
  },

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  template: maybe(p.template),
  location: p.location,

  ...toClientDates(p),
});

export const toClientContent = (p: Graph.Content): Content => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  code: maybe(p.code),
  orders: maybe(p.orders),
  owner: when(p.owner, toClientRef),
  status: safeAs<Status>(p.status),
  summary: when(p.summary, toClientRichText),
  body: when(p.body, toClientRichText),
  publish: p.publish,

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  template: maybe(p.template),
  location: p.location,

  source: {
    source: Integration.Traction,
    type: "content",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientSprint = (p: Graph.Sprint): Sprint => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  code: maybe(p.code),
  orders: maybe(p.orders),
  start: p.start,
  end: p.end,
  duration: maybe(p.duration),
  views: map(p.views, toClientRef),
  people: map(p.people, toClientRef),
  status: safeAs<Status>(p.status),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,

  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "sprint",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientMeeting = (p: Graph.Meeting): Meeting => ({
  ...p,
  id: defs(p.id),

  name: maybe(p.name),
  purpose: maybe(p.purpose),
  summary: when(p.summary, toClientRichText),
  owner: when(p.owner, toClientRef),
  status: safeAs<Status>(p.status),
  start: p.start,
  duration: maybe(p.duration),
  end: p.end,

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "meeting",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientPage = (p: Graph.Page): Page => ({
  ...p,
  id: defs(p.id),

  icon: maybe(p.icon),
  title: maybe(p.title),
  body: when(p.body, toClientRichText),
  owner: when(p.owner, toClientRef),
  status: safeAs<Status>(p.status),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "page",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientProcess = (p: Graph.Process): Process => ({
  ...p,
  id: defs(p.id),

  name: maybe(p.name),
  body: when(p.body, toClientRichText),
  summary: when(p.summary, toClientRichText),
  owner: when(p.owner, toClientRef),
  status: safeAs<Status>(p.status),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "process",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientForm = (p: Graph.Form): Form => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  owner: when(p.owner, toClientRef),
  purpose: maybe(p.purpose),

  entity: safeAs<EntityType>(p.entity),
  useTemplate: when(p.useTemplate, toClientRef),
  overrides: p.overrides,
  inLocation: maybe(p.inLocation),
  fields: p.fields,

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "form",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientCompany = (p: Graph.Company): Company => ({
  ...p,
  id: defs(p.id),

  name: maybe(p.name),
  body: when(p.body, toClientRichText),
  avatar: maybe(p.avatar),
  industry: safeAs<SelectOption>(p.industry),
  type: safeAs<SelectOption>(p.type),
  websites: maybe(p.websites),
  owner: when(p.owner, toClientRef),
  orders: maybe(p.orders),

  ...toNestedFields(p),
  ...toLocationFields(p, "company"),
  ...toClientDates(p),
});

export const toClientContact = (p: Graph.Contact): Contact => ({
  ...p,
  id: defs(p.id),

  name: maybe(p.name),
  avatar: maybe(p.avatar),
  email: maybe(p.email),
  websites: maybe(p.websites),
  type: safeAs<SelectOption>(p.type),
  owner: when(p.owner, toClientRef),
  body: when(p.body, toClientRichText),

  ...toNestedFields(p),
  ...toLocationFields(p, "contact"),
  ...toClientDates(p),
});

export const toClientDeal = (p: Graph.Deal): Deal => ({
  ...p,
  id: defs(p.id),

  title: maybe(p.title),
  body: when(p.body, toClientRichText),
  owner: when(p.owner, toClientRef),
  status: safeAs<Status>(p.status),
  start: p.start,
  end: p.end,
  value: maybe(p.value),

  ...toNestedFields(p),
  ...toLocationFields(p, "deal"),
  ...toClientDates(p),
});

export const toClientRequest = (p: Graph.Request): Request => ({
  ...p,
  id: defs(p.id),
  title: maybe(p.title),
  body: when(p.body, toClientRichText),
  owner: when(p.owner, toClientRef),
  assigned: when(p.assigned, toClientRef),
  status: safeAs<Status>(p.status),
  start: p.start,
  end: p.end,

  ...toNestedFields(p),
  ...toLocationFields(p, "request"),
  ...toClientDates(p),
});

export const toClientEvent = (p: Graph.Event): Event => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  body: when(p.body, toClientRichText),
  status: safeAs<Status>(p.status),
  owner: when(p.owner, toClientRef),
  start: p.start,
  end: p.end,

  ...toNestedFields(p),
  ...toLocationFields(p, "event"),

  ...toClientDates(p),
});

export const toClientWorkflow = (p: Graph.Workflow): Workflow => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  status: safeAs<Status>(p.status),
  owner: when(p.owner, toClientRef),
  orders: maybe(p.orders),
  inputs: p.inputs,
  vars: p.vars,

  ...toNestedFields(p),
  ...toLocationFields(p, "workflow"),

  ...toClientDates(p),
});

export const toClientWorkflowStep = (p: Graph.WorkflowStep): WorkflowStep => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  status: safeAs<Status>(p.status),
  action: when(p.action, (a) => asEnum(a, WorkflowAction)),
  owner: when(p.owner, toClientRef),
  orders: maybe(p.orders),
  inputs: p.inputs,
  outputs: p.outputs,
  condition: p.condition,
  options: p.options,
  overrides: p.overrides,

  ...toNestedFields(p),
  ...toLocationFields(p, "workflow_step"),

  ...toClientDates(p),
});

export const toClientPipeline = (p: Graph.Pipeline): Pipeline => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  owner: when(p.owner, toClientRef),
  orders: maybe(p.orders),
  stageBy: maybe(p.stageBy),

  ...toNestedFields(p),
  ...toLocationFields(p, "pipeline"),

  ...toClientDates(p),
});

export const toClientKnowledgeBase = (
  p: Graph.KnowledgeBase
): KnowledgeBase => ({
  ...p,
  id: defs(p.id),
  icon: maybe(p.icon),
  name: maybe(p.name),
  owner: when(p.owner, toClientRef),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "knowledgebase",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientAgenda = (p: Graph.Agenda): Agenda => ({
  ...p,
  id: defs(p.id),

  code: maybe(p.code),
  open: maybe(p.open),
  title: maybe(p.title),
  order: maybe(p.order),
  color: maybe(p.color) as Maybe<Color>,
  body: when(p.body, toClientRichText),
  notes: when(p.notes, toClientRichText),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "agenda",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientAction = (p: Graph.Action): Action => ({
  ...p,
  id: defs(p.id),

  title: maybe(p.title),
  status: safeAs<Status>(p.status),
  open: maybe(p.open),
  assigned: when(p.assigned, toClientRef),

  start: p.start,
  end: p.end,

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,
  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "action",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientSchedule = (p: Graph.Schedule): Schedule => ({
  ...p,
  id: defs(p.id),
  name: maybe(p.name),
  status: safeAs<Status>(p.status),
  useTemplate: when(p.useTemplate, toClientRef),
  overrides: p.overrides,
  entity: safeAs<EntityType>(p.entity),
  from: p.from,
  to: p.to,
  last: p.last,
  next: p.next,
  period: asEnum(defs(p.period), Period) || Period.Week,
  frequency: maybe(p.frequency) || 1,
  precreate: maybe(p.precreate) ?? 1,
  daysOfPeriod: maybe(p.daysOfPeriod) || [],
  timeOfDay: maybe(p.timeOfDay),
  timeZone: maybe(p.timeZone),
  instances: map(p.instances, toClientRef),

  location: p.location,
  template: maybe(p.template),
  source: {
    source: Integration.Traction,
    type: "schedule",
    scope: toScope(p.location),
  },

  ...toClientDates(p),
});

export const toClientView = (v: Graph.View): View => ({
  id: defs(v.id),
  alias: maybe(v.alias),
  name: v.name || "",
  icon: maybe(v.icon),
  order: maybe(v.order),
  layout: (maybe(v.layout) as Maybe<ViewLayout>) || "list",
  grouping: maybe(v.grouping),
  aggregate: maybe(v.aggregate),

  filter: v.filter as any, // Trust me bro
  entity: (maybe(v.entity) as Maybe<EntityType>) || "task",
  group: v.group,
  sort: v.sort,
  showProps: maybe(v.showProps),
  settings: maybe(v.settings as ViewOptions),

  for: when(v.for, toRef),
  team: when(v.team, toRef),
  source: {
    source: Integration.Traction,
    type: "view",
    scope: toScope(v.location),
  },
  template: maybe(v.template),
  location: v.location,

  fetchedAt: now(),
  updatedAt: v.updatedAt,
  updatedBy: when(v.updatedBy, toClientRef),
  createdAt: v.createdAt,
  createdBy: when(v.createdBy, toClientRef),
});

export const toClientNote = (n: Graph.Note): Note => ({
  id: defs(n.id),
  type: asEnum(defs(n.type), NoteType) || NoteType.Note,
  summary: when(n.summary, toClientRichText),
  title: maybe(n.title),
  body: when(n.body, toClientRichText),
  pinned: maybe(n.pinned),
  author: when(n.author, toClientRef),
  links: map(n.links || [], toClientLink),

  refs: formatRefs(n.refs),
  custom: n.custom,
  stamps: n.stamps,
  location: n.location,
  source: {
    source: Integration.Traction,
    type: "note",
    scope: toScope(n.location),
  },

  ...toClientDates(n),
});

export const toClientResource = (n: Graph.Resource): Resource => ({
  id: defs(n.id),
  type: asEnum(defs(n.type), ResourceType) || ResourceType.Link,
  name: maybe(n.name),
  mimeType: maybe(n.mimeType),
  url: maybe(n.url),
  icon: maybe(n.icon),
  pinned: maybe(n.pinned),

  refs: formatRefs(n.refs),
  custom: n.custom,
  stamps: n.stamps,
  location: n.location,
  source: {
    source: Integration.Traction,
    type: "resource",
    scope: toScope(n.location),
  },

  ...toClientDates(n),
});

export const toClientPropertyDef = <E extends Entity, P extends PropertyType>(
  d: Graph.PropertyDef
): PropertyDef<E, P> => ({
  field: d.field,
  type: d.type as PropertyDef<E, P>["type"],
  entity: d.entity as EntityType[],
  scope: d.scope,
  values: { [d.type]: d.values || [] },
  label: maybe(d.label) || toFieldName(d),
  order: maybe(d.order),
  assist: d.assist || AiAssist.Off,
  options: d.options,
  visibility: d.visibility ?? PropertyVisibility.HideEmpty,
  displayAs: d.displayAs ?? DisplayAs.Property,
  format: d.format as PropertyFormat,
  locked: d.locked ?? false,
  readonly: d.readonly ?? false,
  system:
    !d.id || d.field?.startsWith("refs.") || d.field?.startsWith("settings."),
  createdAt: d.createdAt,
  updatedAt: d.updatedAt,
  deletedAt: d.deletedAt,
});

export const toClientPersonRef = (p: Graph.Person): PersonRef => ({
  id: p.id,
  name: maybe(p.name),
  email: maybe(p.email),
  avatar: undefined,
  source: Integration.Traction,
});

export const toClientPerson = (p: Graph.Person): Person => ({
  id: p.id,
  name: maybe(p.name),
  fullName: maybe(p.fullName),
  email: maybe(p.email),
  role: asEnum(p.role, PersonRole) || PersonRole.Guest,
  avatar: maybe(p.avatar),
  source: {
    source: Integration.Traction,
    type: "person",
    scope: p.id,
  },
  aka: maybe(omitEmpty(p.aka)),
  teams: maybeMap(p.teams || [], toRef),
  views: maybeMap(p.views || [], toRef),

  refs: formatRefs(p.refs),
  custom: p.custom,
  stamps: p.stamps,
  settings: p.settings,

  updatedAt: p.updatedAt,
  updatedBy: when(p.updatedBy, toClientRef),
  createdAt: p.createdAt,
  createdBy: when(p.createdBy, toClientRef),
  deletedAt: p.deletedAt,
  deletedBy: when(p.deletedBy, toClientRef),
  fetchedAt: now(),
});

export const toClientWorkspace = (p: Graph.Workspace): Workspace => ({
  id: p.id,
  name: p.name || "",
  icon: maybe(p.icon),
  setupAt: p.setupAt,
  settings: p.settings,
  source: {
    source: Integration.Traction,
    type: "workspace",
    scope: p.id,
  },
  fetchedAt: now(),
  createdAt: p.createdAt,
  updatedAt: p.updatedAt,
});

export const toClientWorkspaceConfig = (s: Graph.Session): WorkspaceConfig => ({
  token: s.token,
  auths: s.auths || {},
  workspace: when(s.workspace, toClientWorkspace),
  user: toClientPerson(s.person),
});

export const toClientJob = (s: Graph.Job): Job => ({
  id: s.id,
  key: s.key,
  status: asEnum(s.status, JobStatus) || JobStatus.Queued,
  data: s.data,
  attempts: maybe(s.attempts),
  lockKey: maybe(s.lockKey),
  lockedAt: s.lockedAt,
  lockedBy: when(s.lockedBy, toRef),
  createdAt: s.createdAt,
  createdBy: when(s.createdBy, toRef),
  updatedAt: s.updatedAt,
  deletedAt: s.deletedAt,
});

/*
 * Generic Mappers - Update on new entity
 */

// TODO: Type checking hotspot... dodn't know why

// Update on new entity
export const mappers: Mappers = {
  Task: toClientTask,
  Outcome: toClientOutcome,
  Team: toClientTeam,
  Campaign: toClientCampaign,
  Content: toClientContent,
  Calendar: toClientCalendar,
  Note: toClientNote,
  Workspace: toClientWorkspace,
  Resource: toClientResource,
  View: toClientView,
  Project: toClientProject,
  Backlog: toClientBacklog,
  Roadmap: toClientRoadmap,
  Sprint: toClientSprint,
  Schedule: toClientSchedule,
  Person: toClientPerson,
  Page: toClientPage,
  Meeting: toClientMeeting,
  Agenda: toClientAgenda,
  Action: toClientAction,
  Process: toClientProcess,
  Form: toClientForm,
  Event: toClientEvent,
  Request: toClientRequest,
  Workflow: toClientWorkflow,
  WorkflowStep: toClientWorkflowStep,
  Pipeline: toClientPipeline,
  KnowledgeBase: toClientKnowledgeBase,
  Company: toClientCompany,
  Contact: toClientContact,
  Deal: toClientDeal,
};

export function mapFetchable<T extends Graph.Fetchable>(t: T): Entity | Error {
  const tt = t.__typename;

  if (!tt) {
    // You probably forgot to add a new Fetchable union type to
    // client-api/integrations/traction/graph/api.ts
    throw new Error("Missing fragment.");
  }

  const mapper =
    tt === "Error" ? toClientError : (mappers[tt] as Mappers[typeof tt]);

  if (!mapper) {
    // You probably forgot to add a new Fetchable union type to
    // client-api/integrations/traction/graph/api.ts
    throw new Error("Missing fragment mapper.");
  }

  // @ts-ignore - can't get the mappings to line up
  return mapper(t) as Entity | Error;
}

export const mapFetchables = <T extends Graph.Fetchable>(
  ts: Maybe<T[]>
): (Entity | Error)[] => map(ts, mapFetchable);

export function filterMapFetchable<
  T extends Graph.Fetchable,
  TF extends Exclude<Graph.Fetchable["__typename"], "Error">,
  R extends Entity = TF extends "Error"
    ? Error
    : Extract<MapperLookup, { key: TF }>["to"]
>(ts: Maybe<T[]>, allowed: TF): R[] {
  return maybeMap(ts, (t) => {
    const tt = t.__typename;

    if (!tt) {
      // You probably forgot to add a new Fetchable union type to
      // client-api/integrations/traction/graph/api.ts
      throw new Error("Missing fragment.");
    }

    if (allowed && tt !== allowed) {
      debug(`Skipping data of type (${tt}) mapping as not allowed ${allowed}.`);
      return undefined;
    }

    const mapper =
      tt === "Error" ? toClientError : (mappers[tt] as Mappers[typeof tt]);

    if (!mapper) {
      // You probably forgot to add a new Fetchable union type to
      // client-api/integrations/traction/graph/api.ts
      throw new Error("Missing fragment mapper.");
    }

    // @ts-ignore - lol can't get the mappings to line up
    return mapper?.(t) as R;
  });
}
