import { CSSProperties } from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { ComponentOrNode, isComponent } from "@utils/react";

import { Size } from "@ui/types";

import styles from "./styles.module.css";

export interface Props {
  icon: ComponentOrNode;
  size?: Size;
  dark?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: Fn<React.MouseEvent, void>;
}

export const Icon = ({
  icon,
  className,
  size = "default",
  dark = false,
  style,
  onClick,
}: Props) => (
  <span
    onClick={onClick}
    className={cx(
      styles.icon,
      dark && styles.dark,
      styles[`${size}Size`],
      className
    )}
    style={style}
  >
    {isComponent(icon) ? icon({}, undefined) : icon}
  </span>
);

export * from "./check";
export * from "./color-indicator";
export * from "./counter";
export * from "./css";
export * from "./emoji";
export * from "./image";
export * from "./link";
export * from "./none";
export * from "./page";
export * from "./person";
export * from "./spinner";
export * from "./square";
export * from "./status";
export * from "./svgs";
export * from "./team";
export * from "./utils";
export * from "./view";
